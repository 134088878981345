import { Dispatch, FC, ReactNode, SetStateAction, createContext, useState } from "react";

export type ContextType = {
  currentUrl: string,
  setCurrentUrl: Dispatch<SetStateAction<string>>
} | undefined

export const pageContext = createContext<ContextType>(undefined);

interface Iprops {
  children?: ReactNode
}

const PageProvider: FC<Iprops> = ({ children }) => {
  const [currentUrl, setCurrentUrl] = useState('#a') // 当前选择的url

  return <pageContext.Provider value={{
    currentUrl,
    setCurrentUrl
  }}>
    {children}
  </pageContext.Provider>
}
export default PageProvider;
