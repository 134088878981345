import './index.less'

const Footer = () => {
  const urlList = [{
    label: 'icon1',
    url: 'https://twitter.com/venusloopcom',
  }, {
    label: 'icon2',
    url: 'https://t.me/venusloop',
  }, {
    label: 'icon3',
    url: 'https://discord.com/invite/ETGg4ywmyG',
  }, {
    label: 'icon4',
    url: 'https://medium.com/@venusloop',
  }]

  return (
    <>
      <div className='footer-wrap md:h-315' >
        <div className='container mx-auto flex  items-center box-border mmd:px-20 mmd:py-25 header-container justify-between md:h-215'>
          <div className=''>
            <div>
              <img src={`./logo.png`} alt="" className='md:w-240 md:h-46 mmd:w-104 mmd:h-20 block' />
            </div>
            <div className='md:mt-25 md:text-22 desp mmd:mt-7 mmd:text-12'>
              VenusLoop Protocol
            </div>
          </div>
          <div className='flex items-center gap-10 connect-button'>
            <div className='flex md:gap-60 md:mr-50 pc-urlList mmd:gap-20'>
              {urlList.map((val, index) => {
                return <a key={index} href={val.url} className={`flex url-item cursor-pointer`}>
                  <img src={`./images/footer/${val.label}.png`} alt="" className='md:w-36 md:h-36 mmd:w-14 mmd:h-14' />
                </a>
              })}
            </div>
          </div>
        </div>
        <div className='coyright md:text-22 mmd:text-14 md:h-99 mmd:h-44 md:leading-[99px] mmd:leading-[44px]'>
          Coyright © 2024VenusLoop network
        </div>
      </div>
    </>
  )
}

export default Footer
