import { Popover } from 'antd'
import { useContext, useEffect, useMemo, useState } from 'react'
import './index.less'
import { useLocation } from 'react-router-dom';
import { pageContext } from '@/context/pageContext';
import { useScroll } from 'ahooks';
import { useNavigate } from 'react-router-dom';
interface urlItem {
  label: string
  url: string,
  internal?: boolean,
  link?: string
  activeUrl: string[]
}
const Headers = () => {
  const [opened, setopened] = useState(false)
  // const [change, setchange] = useState(0)
  // const menu = [{
  //   label: "華語",
  //   key: 'cn',
  //   icon: <img src="https://cam3lot.io/images/len/zh.png" className='w-[1.2vw] rounded-full' alt={'cn'} />
  // }, {
  //   label: "EngLish",
  //   key: 'enUS',
  //   icon: <img src="https://cam3lot.io/images/len/en.png" className='w-[1.2vw] rounded-full' alt={'cn'} />
  // }]
  const location = useLocation()
  // const [activeUrl, setactiveUrl] = useState(location.pathname)
  useEffect(() => {
    if(location.pathname === '/team') {
      pageProvider?.setCurrentUrl('/team')
    }
    // eslint-disable-next-line
  }, [location.pathname])
  
  const urlList:urlItem[] = [{
    label: 'HOME',
    url: '#home',
    activeUrl: ['/', '#home']
  }, {
    label: 'PRODUCT',
    url: '#product',
    activeUrl: ['#product']
  }, {
    label: 'DOCS',
    url: '#docs',
    link: "https://venusloop-protocol.gitbook.io/doc/",
    activeUrl: ['#docs']
  }, {
    label: 'TEAM',
    internal: true,
    url: '/team',
    activeUrl: ['/team']
  }]
  
  // const currentLang = useMemo(() => {
  //   return menu.find(val => val.key === i18next.language)
  //   // eslint-disable-next-line
  // }, [i18next.language])

  const setItem = (url: string) => {
    pageProvider?.setCurrentUrl(url)
    const top = (document.querySelector(url) as any)?.offsetTop

    window?.scrollTo({
      top: top - 100,
      behavior: 'smooth',
    });
  }

  const pageProvider = useContext(pageContext)

  const scroll = useScroll(document);
  const navigator = useNavigate()

  const top = useMemo(() => {
    return scroll ? scroll?.top > 100 : false
    // eslint-disable-next-line
  }, [scroll?.top])
  const navGo = (val: urlItem) => {
    if (val?.link) {
      window.open(val.link, 'target=_blank')
      return
    }
    if(val.internal) {
      navigator(val.url)
      pageProvider?.setCurrentUrl(val.url)
      window?.scrollTo({
        top: 0
      });
    }else {
      navigator('/')
      setTimeout(() => {
        setItem(val.url)
      }, 0);
    }
    setopened(false)
  }
  useEffect(() => {
    document.body.style.display = opened ? 'hidden' : 'auto'
  }, [opened])
  
  const mobileMenu = (
    urlList.map((val, index) => {
      return <div key={index} className={`flex cursor-pointer pt-31 menu-item ${pageProvider?.currentUrl && val.activeUrl?.includes(pageProvider?.currentUrl) ? 'active' : ''}`} onClick={() => {
        navGo(val)
        setopened(false)
      }}>
        <span className='sans'>{val.label}</span>
      </div>
    })
  )
  return (
    <>
      <div className={`fixed top-0 left-0 right-0 z-10 header-wrap h-110 mmd:h-56 mmd:bg-[#ffffff]/15 mmd:backdrop-blur-[10px] ${top ? 'bg-[#ffffff]/15 backdrop-blur-[10px]' : ''}`} >
        <div className='container mx-auto flex  items-center box-border mmd:px-14 mmd:py-15 header-container justify-between select-none'>
          <div className='flex items-center cursor-pointer' onClick={() => {
            setItem('#home')
          }}>
            <img src="./logo.png" alt="" className='md:w-240 md:h-46 mmd:w-104' />
          </div>
          <div className='flex items-center gap-10 connect-button'>
            <div className='flex mmd:hidden gap-100 mr-50 pc-urlList'>
              {urlList.map((val, index) => {
                return <div key={index} className={`flex url-item cursor-pointer md:text-22 ${pageProvider?.currentUrl && val.activeUrl?.includes(pageProvider?.currentUrl) ? 'active' : ''}`} onClick={() => {navGo(val)}}>
                  <span className='sans'>{val.label}</span>
                </div>
              })}
            </div>
            <Popover content={mobileMenu} open={opened}
            overlayClassName='popover'
              onOpenChange={setopened} arrow={false} placement="bottomRight" trigger="click">
              <img src="./images/list.png" alt="" className='w-24 h-24 md:hidden cursor-pointer' />
            </Popover>
          </div>
        </div>
      </div>
    </>
  )
}

export default Headers
