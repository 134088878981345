import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import Headers from './components/Header';
import PageProvider from './context/pageContext';
import Footer from './components/Footer';


function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <PageProvider>
          <Headers />
          <Routes />
          <Footer />
        </PageProvider>
      </BrowserRouter>
    </div >
  );
}

export default App;
